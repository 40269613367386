.sc-loader {
  --loader-background: var(--primary-500);
  display: flex;
  justify-self: center;
  align-self: center;
  border-radius: 50%;
  width: 7px;
  height: 7px;
  background: var(--loader-background, white);
  transition: opacity 0.2;
  -webkit-animation-name: loader-blink;
          animation-name: loader-blink;
  -webkit-animation-duration: 1.4s;
          animation-duration: 1.4s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  margin: 0 20px;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-delay: 0.15s;
          animation-delay: 0.15s; }
  .sc-loader[data-theme="dark"] {
    --loader-background: white; }
  .sc-loader[data-theme="light"] {
    --loader-background: var(--primary-500); }
  .sc-loader:before {
    content: "";
    position: absolute;
    border-radius: inherit;
    background: inherit;
    left: -16px;
    width: 100%;
    height: 100%;
    top: 0;
    -webkit-animation-name: loader-blink;
            animation-name: loader-blink;
    -webkit-animation-duration: 1.4s;
            animation-duration: 1.4s;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both; }
  .sc-loader:after {
    content: "";
    position: absolute;
    border-radius: inherit;
    background: inherit;
    right: -16px;
    width: 100%;
    height: 100%;
    top: 0;
    -webkit-animation-name: loader-blink;
            animation-name: loader-blink;
    -webkit-animation-duration: 1.4s;
            animation-duration: 1.4s;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    -webkit-animation-delay: 0.3s;
            animation-delay: 0.3s; }

@-webkit-keyframes loader-blink {
  from,
  to {
    background: transparent;
    transform: scale(0.95); }
  50% {
    background: var(--loader-background, white);
    transform: scale(1); } }

@keyframes loader-blink {
  from,
  to {
    background: transparent;
    transform: scale(0.95); }
  50% {
    background: var(--loader-background, white);
    transform: scale(1); } }
