[data-reach-hover-popover] {
  box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.35), 0 1px 4px 0 rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  overflow: hidden;
  opacity: 0;
  transform: scale(0.95);
  transition: opacity 0.2s, transform 0.2s;
  visibility: hidden;
  position: relative;
  z-index: 20; }
  [data-reach-hover-popover][data-is-visible="false"] {
    left: -9999px;
    top: 0; }
  [data-reach-hover-popover][data-is-visible="true"] {
    opacity: 1;
    transform: scale(1);
    visibility: visible; }
