.tablet-preview .tablet-preview-expanded {
  flex-direction: column; }
  .tablet-preview .tablet-preview-expanded > * {
    text-decoration: none; }
  .tablet-preview .tablet-preview-expanded .tablet-header-section {
    display: grid;
    grid-template-rows: auto auto;
    grid-template-columns: auto 1fr;
    grid-template-areas: "symbol collection" "symbol title"; }
  .tablet-preview .tablet-preview-expanded .symbol-container {
    grid-area: symbol;
    align-self: center; }
  .tablet-preview .tablet-preview-expanded .stat-symbol-layout {
    width: 32px;
    height: 32px;
    margin-right: 12px; }
  .tablet-preview .tablet-preview-expanded .collection-links-container {
    grid-area: collection; }
  .tablet-preview .tablet-preview-expanded .tablet-title-container {
    text-decoration: none;
    grid-area: title; }
  .tablet-preview .tablet-preview-expanded .tablet-title {
    font-size: 1.25rem; }
  .tablet-preview .tablet-preview-expanded .tablet-preview-text {
    font-size: 0.875rem;
    font-weight: 500;
    margin-bottom: 0.5rem;
    line-height: 1.3;
    color: var(--neutral-600);
    padding-top: 0.625rem;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    display: -webkit-box; }
