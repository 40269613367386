.sc-tooltip-triangle {
  position: absolute;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #314079;
  z-index: 1000; }
  .sc-tooltip-triangle[data-type="info"] {
    border-bottom: 10px solid #314079; }
  .sc-tooltip-triangle[data-type="warning"] {
    border-bottom: 10px solid var(--accent-500); }

.sc-tooltip {
  border-radius: 4px;
  border: none;
  color: rgba(255, 255, 255, 0.9);
  padding: 1rem 1.25rem;
  font-size: 15px;
  line-height: 1.5;
  box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.2);
  max-width: calc(100vw - 24px);
  background: #314079;
  z-index: 1000;
  pointer-events: none;
  position: absolute;
  max-width: 270px;
  margin-top: 6px;
  text-align: center; }
  .sc-tooltip[data-type="info"] {
    background: #314079; }
  .sc-tooltip[data-type="warning"] {
    background: var(--accent-500); }
