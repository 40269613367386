.tablet-preview {
  display: flex;
  position: relative; }
  .tablet-preview[data-contained="true"] {
    border-radius: 8px;
    padding: 0.75rem;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.25), 0 2px 6px 0 rgba(0, 0, 0, 0.15);
    cursor: pointer; }
    .tablet-preview[data-contained="true"]:hover {
      box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15), 0 1px 3px rgba(0, 0, 0, 0.1);
      transform: scale(1.01); }
  .tablet-preview .tablet-details {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    text-decoration: none; }
    .tablet-preview .tablet-details > * {
      text-decoration: none; }
  .tablet-preview .tablet-title {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    font-size: 1.125rem;
    font-weight: 600;
    padding: 0;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    color: var(--neutral-900); }
  .tablet-preview .tablet-metadata {
    display: flex;
    color: var(--neutral-500);
    font-size: 0.75rem;
    font-weight: 500;
    padding: 0;
    list-style: none;
    position: relative; }
    .tablet-preview .tablet-metadata > li {
      position: relative; }
    .tablet-preview .tablet-metadata > :not(:last-child) {
      margin-right: 15px; }
      .tablet-preview .tablet-metadata > :not(:last-child):after {
        content: "";
        width: 3px;
        height: 3px;
        background: var(--neutral-400);
        position: absolute;
        right: -9px;
        top: calc(50% - 1.5px);
        border-radius: 50%; }
  .tablet-preview .badge-container {
    position: absolute;
    right: -8px;
    top: 6px; }
  .tablet-preview .collection-links-container {
    margin: 0 0 0.125rem;
    overflow: hidden; }
  .tablet-preview .preview-metadata-container {
    margin-top: 0.125rem; }
  .tablet-preview[data-contained="false"] > .badge-container {
    display: none; }
  .tablet-preview[data-contained="true"] > .badge-container {
    position: absolute;
    right: -8px;
    top: -6px; }
