.scribe-badge {
  background: var(--attention-300);
  color: var(--attention-800);
  font-weight: 700;
  font-size: 0.75rem;
  border-radius: 2px;
  padding: 2px 1rem;
  display: flex;
  justify-content: center;
  justify-content: center;
  box-shadow: 0 2px 4px 0 rgba(178, 178, 178, 0.35); }
  .scribe-badge[data-type="attention"] {
    background: var(--attention-300);
    color: var(--attention-800); }
  .scribe-badge[data-type="danger"] {
    background: var(--danger-200);
    color: var(--danger-800); }
