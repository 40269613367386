.sc-button {
  align-content: center;
  justify-content: center;
  border-radius: 0.5rem;
  cursor: pointer;
  box-sizing: border-box;
  border: none;
  transition: all 0.3s;
  display: inline-flex;
  white-space: nowrap;
  font-size: 0.875rem;
  padding: 0.5rem 1.25rem;
  color: inherit;
  background: white;
  position: relative;
  width: 100%; }
  .sc-button[data-loading="true"] > :not(:last-child) {
    visibility: hidden;
    opacity: 0; }
  .sc-button .loader-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    visibility: hidden;
    opacity: 0; }
  .sc-button[data-loading="true"] .loader-container {
    visibility: visible;
    opacity: 1; }
  .sc-button .button-content {
    display: flex;
    align-items: center;
    align-self: center;
    overflow: hidden; }
  .sc-button[data-type="primary"] {
    --button-color: var(--primary-500);
    --highlight-color: var(--primary-400); }
  .sc-button[data-type="danger"] {
    --button-color: var(--danger-500);
    --highlight-color: var(--danger-400); }
  .sc-button[data-theme="light"] {
    --button-color: var(--primary-500); }
  .sc-button[data-theme="dark"] {
    --button-color: var(--highlight-600); }
  .sc-button[data-variant="primary"] {
    background: var(--button-color);
    color: white;
    border: 1px solid var(--button-color); }
  .sc-button[data-variant="primary"]:not([disabled]):hover {
    background: var(--highlight-color);
    border: 1px solid var(--highlight-color); }
  .sc-button[data-variant="secondary"] {
    background: white;
    color: var(--button-color);
    border: 1px solid var(--button-color); }
  .sc-button[data-theme="dark"][data-variant="secondary"] {
    color: white;
    border: 1px solid var(--button-color);
    background: transparent; }
  .sc-button[data-size="large"] {
    font-size: 1.25rem;
    height: 3.5rem;
    max-width: 12.5rem; }
  .sc-button[data-size="medium"] {
    font-size: 1rem;
    height: 2.625rem; }
  .sc-button[data-variant="secondary"][disabled], .sc-button[data-variant="primary"][disabled] {
    background: white;
    color: var(--neutral-400);
    cursor: not-allowed;
    border: 1px solid var(--neutral-300); }
  .sc-button:focus {
    outline: none;
    background: var(--highlight-color);
    border: 1px solid var(--highlight-color);
    color: white; }
