[data-popover-overlay] {
  background: rgba(0, 0, 0, 0);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  display: flex;
  z-index: 101;
  overflow: hidden; }
  [data-popover-overlay][data-show="false"] {
    pointer-events: none;
    visibility: hidden;
    opacity: 0; }

@-webkit-keyframes scale-up-popover-center {
  0% {
    transform: scale(1);
    opacity: 0; }
  1% {
    transform: scale(0.8);
    opacity: 0; }
  2% {
    transform: scale(0.8);
    opacity: 0.5; }
  100% {
    transform: scale(1);
    opacity: 1; } }

@keyframes scale-up-popover-center {
  0% {
    transform: scale(1);
    opacity: 0; }
  1% {
    transform: scale(0.8);
    opacity: 0; }
  2% {
    transform: scale(0.8);
    opacity: 0.5; }
  100% {
    transform: scale(1);
    opacity: 1; } }

[data-reach-popover] {
  box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.35), 0 1px 4px 0 rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  overflow: hidden;
  opacity: 0;
  transform: scale(0.95);
  transition: opacity 0.2s, transform 0.2s;
  visibility: hidden;
  position: relative;
  background: white;
  overflow-y: scroll;
  z-index: 102; }
  [data-reach-popover][data-fixed="false"]::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px; }
  [data-reach-popover][data-fixed="false"]::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5); }
  [data-reach-popover][data-fixed="true"] {
    overflow: unset; }
  [data-reach-popover][data-is-visible="false"] {
    left: -9999px !important;
    top: 0 !important;
    right: unset !important; }
  [data-reach-popover][data-is-visible="true"] {
    opacity: 1;
    transform: scale(1);
    visibility: visible;
    -webkit-animation-name: scale-up-popover-center;
            animation-name: scale-up-popover-center;
    -webkit-animation-duration: 0.1s;
            animation-duration: 0.1s;
    -webkit-animation-iteration-count: 1;
            animation-iteration-count: 1;
    -webkit-animation-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
            animation-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
    -webkit-animation-delay: 0.01s;
            animation-delay: 0.01s;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both; }
