.tablet-preview-collection-links {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: var(--neutral-500);
  display: flex;
  align-items: flex-end; }
  .tablet-preview-collection-links a {
    text-decoration: none;
    color: inherit;
    overflow: hidden; }
  .tablet-preview-collection-links .more-links {
    color: var(--neutral-500);
    font-size: 0.75rem;
    font-weight: 500;
    position: relative;
    top: -1px;
    cursor: pointer; }
    .tablet-preview-collection-links .more-links:hover {
      color: var(--primary-500); }
  .tablet-preview-collection-links .more-links-join {
    color: var(--neutral-700);
    font-size: 0.75rem;
    font-weight: 500;
    padding: 0 0.25rem;
    position: relative;
    top: -1px; }
  .tablet-preview-collection-links .collection-name {
    color: inherit;
    text-transform: uppercase;
    font-size: 0.75rem;
    font-weight: 500;
    padding: 0;
    margin: 0; }
    .tablet-preview-collection-links .collection-name:hover {
      color: var(--primary-500); }

.more-links-section {
  padding: 1rem 1.5rem 0.75rem 1.5rem;
  background: white; }
  .more-links-section > h3 {
    padding-bottom: 0.5rem;
    padding-right: 1rem;
    font-weight: 500;
    letter-spacing: 0.2px;
    text-transform: uppercase;
    font-size: 0.875rem;
    color: var(--neutral-500);
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none; }
  .more-links-section > ul {
    list-style: none;
    padding: 0;
    margin: 0; }
    .more-links-section > ul > li {
      line-height: 2.4;
      cursor: pointer;
      transition: background 0.1s ease-in-out, padding 0.2s; }
    .more-links-section > ul > li:hover {
      background: var(--neutral-100);
      padding-left: 0.125rem; }
    .more-links-section > ul a {
      text-decoration: none; }
  .more-links-section .collection-name {
    color: var(--neutral-700);
    font-size: 1rem;
    font-weight: 600;
    padding: 0 0.25rem;
    margin: 0; }
    .more-links-section .collection-name:hover {
      color: var(--neutral-800); }
