[data-avatar] {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  position: relative;
  overflow: hidden;
  -webkit-mask-image: -webkit-radial-gradient(white, black); }
  [data-avatar][data-square="true"] {
    border-radius: 12px; }

.placeholder-avatar {
  width: 100%;
  height: 100%;
  background: var(--primary-500);
  color: #fff;
  font-size: 20px;
  border: none;
  text-align: center; }

.placeholder-text {
  text-anchor: middle;
  dominant-baseline: middle;
  pointer-events: auto;
  fill: currentColor;
  font-size: 0.5rem;
  font-family: muli; }
