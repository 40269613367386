.avatar-edit-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: rgba(0, 0, 0, 0.8);
  color: white;
  font-size: 0.875rem;
  letter-spacing: 0.4px;
  border: none;
  cursor: pointer;
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s;
  border-radius: inherit; }
  .avatar-edit-overlay[data-always-visible="true"] {
    visibility: visible;
    opacity: 1; }

[data-avatar]:hover > .avatar-edit-overlay {
  visibility: visible;
  opacity: 1; }
