.scribe-input {
  position: relative;
  font-size: 1rem;
  display: flex;
  background: inherit;
  /* Using CSS variables allows us to supply a theme via a react prop which will change the color scheme.
    It also allows us to supply no theme and override the styles from a parent components stylesheet. This is
    awesome because it allows for pure css theming which works with SSR. I have chosen to default to the light
    theme if no theme is supplied and the values are not supplied by the parent */
  --input-color-light: var(--neutral-900);
  --input-color-dark: white;
  --label-color-light: var(--neutral-400);
  --label-color-dark: var(--neutral-200);
  --label-color-focus-light: var(--primary-500);
  --label-color-focus-dark: var(--highlight-500);
  --border-color-light: var(--primary-400);
  --border-color-dark: var(--highlight-400); }
  .scribe-input[data-size="large"] {
    font-size: 1.75rem; }
    .scribe-input[data-size="large"] .error-text {
      bottom: 0.25em;
      font-size: 1rem; }
  .scribe-input .input-container {
    width: 100%;
    background: inherit; }
  .scribe-input .input-container[data-error="true"]:after {
    border-bottom: 1px solid var(--danger-200); }
  .scribe-input .input-container[data-error="true"]:focus-within:after {
    border-bottom: 1px solid var(--danger-300); }
  .scribe-input[data-theme="light"] .input-container:after {
    --border-color: var(--border-color-light); }
  .scribe-input[data-theme="dark"] .input-container:after {
    --border-color: var(--border-color-dark); }
  .scribe-input .input-container:after {
    left: 0;
    right: 0;
    bottom: 1em;
    content: "";
    position: absolute;
    transform: scaleX(0);
    transition: transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    pointer-events: none;
    border-bottom: 1px solid var(--border-color, var(--border-color-light)); }
  .scribe-input[data-theme="light"] .label-text {
    --label-color: var(--label-color-light); }
  .scribe-input[data-theme="dark"] .label-text {
    --label-color: var(--label-color-dark); }
  .scribe-input[data-theme="light"] [data-scribe-input]:focus + .label-text {
    --label-color-focus: var(--label-color-focus-light); }
  .scribe-input[data-theme="dark"] [data-scribe-input]:focus + .label-text {
    --label-color-focus: var(--label-color-focus-dark); }
  .scribe-input [data-scribe-input][data-fixed="false"]:focus + .label-text {
    --label-color: var(--label-color-focus, var(--label-color-focus-light)); }
  .scribe-input .label-text {
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(0, 1.25em) scale(1);
    z-index: 2;
    background: inherit;
    transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    color: var(--label-color, var(--label-color-light)); }
  .scribe-input [data-scribe-input][data-fixed="true"] + .label-text {
    background: transparent;
    padding: 0 0.25em;
    transform: none;
    top: 0.5rem; }
  .scribe-input[data-contained="true"] .label-text {
    transform: translate(0.75rem, 1.75rem) scale(1); }
  .scribe-input[data-contained="true"] [data-scribe-input][data-fixed="true"] + .label-text {
    transform: translateX(0.75rem);
    top: 0.75rem; }

@-webkit-keyframes appear {
  0% {
    transform: translateX(-4%);
    opacity: 0.01; }
  20% {
    transform: translateX(-2%);
    opacity: 0.9; }
  100% {
    transform: translateX(0);
    opacity: 1; } }

@keyframes appear {
  0% {
    transform: translateX(-4%);
    opacity: 0.01; }
  20% {
    transform: translateX(-2%);
    opacity: 0.9; }
  100% {
    transform: translateX(0);
    opacity: 1; } }
  .scribe-input .error-text {
    pointer-events: none;
    position: absolute;
    bottom: 0;
    right: 0;
    font-size: 0.75rem;
    color: var(--danger-400);
    -webkit-animation: 0.3s ease-out 0s 1 appear;
            animation: 0.3s ease-out 0s 1 appear; }
  .scribe-input[data-theme="light"] [data-scribe-input] {
    --input-color: var(--input-color-light); }
  .scribe-input[data-theme="dark"] [data-scribe-input] {
    --input-color: var(--input-color-dark); }
  .scribe-input [data-scribe-input] {
    position: relative;
    border: none;
    margin-top: 1em;
    margin-bottom: 1em;
    width: 100%;
    line-height: 1.25rem;
    padding: 0.375rem 0.25rem;
    border-bottom: 1px solid var(--neutral-500);
    background: inherit;
    color: var(--input-color, var(--input-color-light));
    border-radius: 0;
    -webkit-appearance: none; }
    .scribe-input [data-scribe-input][data-fixed="true"] {
      margin-top: 0; }
    .scribe-input [data-scribe-input]:disabled {
      color: var(--neutral-500);
      cursor: not-allowed;
      border-bottom: 1px solid var(--neutral-300); }
  .scribe-input[data-contained="true"] [data-scribe-input] {
    border: 1px solid var(--neutral-400);
    border-radius: 4px;
    padding: 0.75rem 0.75rem; }
    .scribe-input[data-contained="true"] [data-scribe-input]:disabled {
      border: 1px solid var(--neutral-300); }
  .scribe-input [data-scribe-input]:focus {
    outline: none; }
  .scribe-input [data-scribe-input]:invalid {
    box-shadow: none; }
  .scribe-input [data-scribe-input]:disabled + .label-text {
    color: var(--neutral-200); }
  .scribe-input [data-scribe-input][data-value="true"]:disabled + .label-text {
    color: var(--neutral-300); }
  .scribe-input[data-contained="true"] [data-scribe-input][data-fixed="false"]:focus + .label-text,
  .scribe-input[data-contained="true"] [data-scribe-input][data-fixed="false"][data-value="true"] + .label-text {
    transform: translate(0.5rem, 0.5rem) scale(0.75);
    transform-origin: top left;
    padding: 0 0.5rem; }
  .scribe-input [data-scribe-input][data-fixed="false"]:focus + .label-text,
  .scribe-input [data-scribe-input][data-fixed="false"][data-value="true"] + .label-text {
    transform: translate(-0.25rem, 0) scale(0.75);
    transform-origin: top left;
    padding: 0 0.5rem; }
  .scribe-input .input-container[data-error="true"] [data-scribe-input]:focus + .label-text {
    color: var(--danger-500); }
  .scribe-input[data-contained="false"] .input-container:focus-within:after {
    transform: scaleX(1); }
  .scribe-input[data-contained="false"] .input-container[data-error="true"]:after {
    transform: scaleX(1); }
  .scribe-input[data-contained="true"] [data-scribe-input]:focus {
    border: 1px solid var(--primary-300); }
  .scribe-input[data-contained="true"] .input-container[data-error="true"] input {
    border: 1px solid var(--danger-200); }
  .scribe-input .reveal-password-toggle-container {
    position: absolute;
    top: 50%;
    right: 16px;
    transform: translateY(-50%);
    color: var(--neutral-400);
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    padding: 0;
    background: transparent;
    border: none; }
    .scribe-input .reveal-password-toggle-container > svg {
      height: 18px; }
