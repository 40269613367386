.preview-metadata {
  display: flex;
  color: var(--neutral-500);
  font-size: 0.75rem;
  font-weight: 500;
  padding: 0;
  list-style: none;
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }
  .preview-metadata .author {
    color: inherit;
    font-size: inherit;
    text-decoration: none; }
    .preview-metadata .author:hover {
      color: var(--primary-500); }
  .preview-metadata > li {
    position: relative; }
  .preview-metadata > :not(:first-child) {
    margin-left: 15px; }
    .preview-metadata > :not(:first-child):after {
      content: "";
      width: 3px;
      height: 3px;
      background: var(--neutral-400);
      position: absolute;
      left: -9px;
      top: calc(50% - 1.5px);
      border-radius: 50%; }
